import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductTitle.module.scss';
import {
  Link,
  ProductPrice,
  ProductOrderButton,
  ResponsiveLazyImage,
  StarsRating,
} from 'sana/elements';
import { productPage } from 'sana/routes';
import { LazyContent } from '../../utils/lazyLoader';
import classNames from 'classnames';
import { SimpleText } from 'sana/texts';

const ProductTile = ({ product, showRatings, noImage }) => {
  const to = useMemo(() => productPage(product.id), [product]);
  const isConfigurable =
    product.productConfiguratorInfo &&
    product.productConfiguratorInfo.isProductConfigurable;
  const productImage =
    product.image && product.image.medium ? product.image.medium : noImage;
  return (
    <div className={styles.productTile}>
      <div className={styles.productDetails}>
        <div className={styles.productInner}>
          <div className={styles.imgWrp}>
            <div className={styles.imgContainer}>
              <LazyContent wait={product.id} className={styles.skeltoncon}>
                <Link url={product.url} to={to} className={styles.imgContainer}>
                  <ResponsiveLazyImage
                    src={productImage}
                    alt={product.title}
                    className={classNames(
                      styles.image,
                      productImage === noImage && styles.noImage,
                    )}
                    title={product.title}
                    draggable="false"
                  />
                </Link>
              </LazyContent>
            </div>
          </div>
        </div>

        <div className={styles.productTitle}>
          <LazyContent wait={product.title}>
            <Link url={product.url} to={to}>
              <h2>{product.title}</h2>
            </Link>
          </LazyContent>
        </div>

        <div className={styles.itemNoWrapper}>
          <LazyContent wait={product.id} className={styles.itemNoPlaceholder}>
            <div className={styles.itemNoContent}>
              <span> {product.id}</span>
            </div>
          </LazyContent>
        </div>

        <div className={styles.productPrice}>
          <LazyContent wait={product.price} className={styles.pricesPlaceholder}>
            <ProductPrice
              wait={product.price}
              salesPrice={product.price}
              basePrice={product.listPrice}
            />
          </LazyContent>
        </div>

        {showRatings && product.reviews !== undefined && (
          <LazyContent
            wait={product.reviews.total}
            className={styles.reviewPlaceholder}
          >
            <Link
              url={`${product.url}#reviews`}
              to={to}
              className={styles.reviewWrapper}
            >
              <StarsRating value={product.reviews.avg} />
              <div className={styles.reviewTotal}>
                ({product.reviews.total} <SimpleText textKey={'Reviews'} />)
              </div>
            </Link>
          </LazyContent>
        )}
        <div className={styles.productOrderButtonWrapper}>
          <LazyContent wait={product.id} className={styles.buttonPlaceholder}>
            <ProductOrderButton
              productId={product.id}
              url={product.url}
              to={to}
              uom={product.uom}
              isOrderable={product.isOrderable}
              isConfigurable={isConfigurable}
              hasVariants={product.hasVariants}
            />
          </LazyContent>
        </div>
      </div>
    </div>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    price: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    uom: PropTypes.object,
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    productConfiguratorInfo: PropTypes.shape({
      isProductConfigurable: PropTypes.bool,
    }),
  }),
  noImage: PropTypes.string,
  showRatings: PropTypes.bool,
};
export default ProductTile;
